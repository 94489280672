import { Box, Button, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { iotCommandsRS, setIotData } from "../../services/redux/BuildingSlice";
import { executePUTRequest } from "../../services/openhab/Utils";

export default function ConfirmationModal({ modalOpen, setModalOpen, onClose, action }) {
  const dispatch = useDispatch();
  const iotCommands = useSelector(iotCommandsRS);
  const { iot, command } = action;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  if (!modalOpen) {
    return null;
  }

  async function sendCommand() {
    setModalOpen(false);
    let response = await executePUTRequest(iotCommands[iot], command);
    if (response === "Accepted") dispatch(setIotData({ data: command, iot }));
  }

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box sx={style}>
        <Typography textAlign={"center"} fontSize={"1.2rem"}>
          Are you sure?
        </Typography>
        <Box display={"flex"} width={"100%"} justifyContent={"space-around"} pt={2}>
          <Button onClick={sendCommand} sx={{ color: "red", fontSize: "1.1rem" }}>
            Yes
          </Button>
          <Button onClick={onClose} sx={{ color: "green", fontSize: "1.1rem" }}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
