import Fab from "@mui/material/Fab";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fullScreenRS, setFullScreen } from "../../services/redux/ContentSlice";
import { useScreenMode } from "../../services/mui/utils";

export default function FAB() {
  const dispatch = useDispatch();
  const fullScreen = useSelector(fullScreenRS);
  const screenMode = useScreenMode();

  if (screenMode === "mobile") return <></>;

  return (
    <Fab
      color="primary"
      aria-label="add"
      sx={{ position: "fixed", right: 10, bottom: 25 }}
      onClick={() => dispatch(setFullScreen(!fullScreen ?? false))}
    >
      {fullScreen ? <FullscreenExit /> : <Fullscreen />}
    </Fab>
  );
}
