import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { isLoadingRS } from "../redux/AppUISlice";

const Loading = () => {
  const isLoading = useSelector(isLoadingRS);
  return <>{isLoading ? <LinearProgress sx={{ position: "absolute", width: "calc(100vw)", zIndex: 1250 }} /> : <></>}</>;
};

export default Loading;
