import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";

import setFlavour from "./flavours/flavour";
import Router from "./services/router/Router";
import FooterSelector from "./components/ui/Footer/FooterSelector";
import { setupApp, useAppEffects } from "./components/utils/SetupApp";
import "./App.css";
import { SnackbarProvider } from "notistack";

function App() {
  const [theme, setTheme] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setTheme(setFlavour());
    setupApp(dispatch);
    // eslint-disable-next-line
  }, []);

  useAppEffects();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
        <div className="App">
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Router />
          </Box>
          <FooterSelector />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
