import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { flavour } from "../../flavours/flavour";
import "./Login.css";
import FirebaseAuthUI from "./../../services/firebase/FirebaseAuthScreen";

const Login = () => {
  const {
    login: { background, loginText, logo },
  } = flavour;
  const backgroundImage = {
    backgroundImage: ` url(${background})`,
  };

  return (
    <CssVarsProvider>
      <div className="login_form">
        <div className="login_form_container">
          <img src={logo} alt="logo" />
          <span>{loginText}</span>
          <FirebaseAuthUI />
        </div>
      </div>
      <div className="login_bg" style={backgroundImage}></div>
    </CssVarsProvider>
  );
};

export default Login;
