import { configureStore } from "@reduxjs/toolkit";
import UserStateSlice from "./UserStateSlice";
import AppUISlice from "./AppUISlice";
import BuildingSlice from "./BuildingSlice";
import ContentSlice from "./ContentSlice";

const store = configureStore({
  reducer: {
    appUI: AppUISlice,
    userState: UserStateSlice,
    building: BuildingSlice,
    content: ContentSlice,
  },
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
