import { numberWithCommas, getIconClassByIotType, convertBinary2OnOff } from "./Transformations";

export function evaluateExpressionInContext(expression, iotData) {
  const context = {
    numberWithCommas,
    getIconClassByIotType,
    convertBinary2OnOff,
    iotData,
  };

  const processedExpression = expression.replace(/\$\{([^}]+)\}/g, (_, code) => {
    const dynamicCode = `return ${code}`;
    const functionName = extractFunctionName(code);

    // eslint-disable-next-line no-new-func
    const dynamicFunction = new Function(functionName, "iotData", dynamicCode);
    return dynamicFunction.call(null, context[functionName], context.iotData);
  });

  return processedExpression;
}

function extractFunctionName(code) {
  const match = code.match(/(\w+)\(/);
  if (match) {
    return match[1];
  }
  return "numberWithCommas"; // Cannot return empty function
}
