import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";

import Auth, { uiConfig, uiConfigMicrosoft } from "./Auth";

import "firebaseui/dist/firebaseui.css";
import { flavour } from "../../flavours/flavour";

const FirebaseAuthUI = () => {
  const { signinOptions } = flavour;

  useEffect(() => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(Auth);
    switch (signinOptions) {
      case "email-google":
        ui.start("#firebaseui-auth-container", uiConfig);
        break;
      case "email-google-microsoft":
        ui.start("#firebaseui-auth-container", uiConfigMicrosoft);
        break;
      default:
        break;
    }
    return;
  });

  return <div id="firebaseui-auth-container"></div>;
};

export default FirebaseAuthUI;
