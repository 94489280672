import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import Login from "../../screens/Login/Login";
import Loading from "./Loading";
import LoadingSuspense from "./LoadingSuspense";
import { isLoggedInRS } from "../redux/UserStateSlice";
import { isLoadingRS } from "../redux/AppUISlice";
import FAB from "../../components/ui/FAB";
import { fullScreenRS } from "../redux/ContentSlice";
import { footerHeight, footerMargin } from "../mui/settings";
import { useAppLazyEffects } from "../../components/utils/SetupApp";

const DrawerHeader = lazy(() => import("../../components/ui/DrawerHeader"));
const SideBar = lazy(() => import("../../components/ui/SideBar"));
const TopBar = lazy(() => import("../../components/ui/Topbar/TopBar"));
const ContentScreen = lazy(() => import("./../../screens/ContentScreen/ContentScreen"));

function Router() {
  const isLoggedIn = useSelector(isLoggedInRS);
  const isLoading = useSelector(isLoadingRS);
  const fullScreen = useSelector(fullScreenRS);
  useAppLazyEffects();

  function getContentScreen() {
    if (isLoading) return <Loading />;
    return <ContentScreen mobile={true} />;
  }

  if (isLoading) return <Loading />;
  if (!isLoggedIn) return <Login />;

  return (
    <Suspense fallback={<LoadingSuspense />}>
      {fullScreen ? <></> : <TopBar />}
      {fullScreen ? <></> : <SideBar />}
      <Box component="main" sx={{ p: 2, minHeight: `calc(100vh - ${footerHeight + footerMargin}px)` }}>
        {fullScreen ? <></> : <DrawerHeader />}
        {getContentScreen()}
      </Box>
      <FAB />
    </Suspense>
  );
}

export default Router;
