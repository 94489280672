import { initializeApp } from "firebase/app";

let firebaseConfig = JSON.parse(process.env.REACT_APP_EC3_PROJECT_ID);

const App = initializeApp(firebaseConfig);

export default App;

// // Blaxand
// let firebaseConfig = {
//   apiKey: "AIzaSyB8ydzANKayxZQOKl_kGUJ473O1zLhfYE4",
//   authDomain: "blaxand-ec3-preprod.firebaseapp.com",
//   databaseURL: "https://blaxand-ec3-preprod-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "blaxand-ec3-preprod",
//   storageBucket: "blaxand-ec3-preprod.appspot.com",
//   messagingSenderId: "794816751808",
//   appId: "1:794816751808:web:46b3800222f346a983e525",
//   measurementId: "G-DZ6F2QMZGY",
// };

// // NMH
// let firebaseConfig = {
//   apiKey: "AIzaSyBf8xBqyDqjlDzOgJ9tP2fcfLoDqcrhIgo",
//   authDomain: "hotel-experience.firebaseapp.com",
//   projectId: "hotel-experience",
//   storageBucket: "hotel-experience.appspot.com",
//   messagingSenderId: "763889188160",
//   appId: "1:763889188160:web:f065904f0e49842d1fcea5",
//   measurementId: "G-NN3J15PD8T",
// };

// // DDL
// let firebaseConfig = {
//   apiKey: "AIzaSyCVMe7HPiwiWnXoT6q1AveHZbpUZG04T3w",
//   authDomain: "automation-of-billing.firebaseapp.com",
//   databaseURL: "https://automation-of-billing-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "automation-of-billing",
//   storageBucket: "automation-of-billing.appspot.com",
//   messagingSenderId: "347547935894",
//   appId: "1:347547935894:web:c62b58b4cf44a6e1a28eb7",
//   measurementId: "G-GJ3E10VRGN",
// };
