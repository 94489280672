/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../services/firebase/Auth";
import {
  executeCollectionCalls,
  executeCollectionSitemapCalls,
  executeDocCalls,
  getSiteMap,
  setMenuById,
  subsCollections,
  subsUserProfile,
} from "./../../services/firebase/DbUtils";
import { setCurrentPath, setIsLoading } from "../../services/redux/AppUISlice";
import { selectedBuildingRS, buildingsRS, setUserLoggedIn, currentServicesRS } from "../../services/redux/UserStateSlice";
import { isLoggedInRS } from "./../../services/redux/UserStateSlice";
import {
  collectionCallsRS,
  collectionSitemapCallsRS,
  collectionSubsRS,
  constantCallsRS,
  docCallsRS,
  iotCallsRS,
  iotSitemapCallsRS,
  locationRS,
  samplingRS,
  serviceRS,
  setIotData,
  setLocation,
  setPathSegmemts,
} from "../../services/redux/BuildingSlice";
import { executeIotCalls, executeIotSitemapCalls } from "../../services/openhab/Utils";
import { toISODateNoHyphen } from "./Transformations";

export function setupApp(dispatch) {
  // Stall to check if user is already logged in
  setTimeout(() => {
    dispatch(setIsLoading(false));
  }, 1000);

  // Listen to user login status
  Auth.onAuthStateChanged(async (user) => {
    dispatch(setUserLoggedIn(user !== null));
  });
}

export function useAppEffects() {
  const dispatch = useDispatch();

  useUserLogin(dispatch);
  useBuildingSelect(dispatch);
}

export function useAppLazyEffects() {
  const dispatch = useDispatch();

  // useNavigateToPath(dispatch);
  useSitemap(dispatch);
  useIotCalls(dispatch);
  useIotSitemapCalls(dispatch);
  useDocCalls(dispatch);
  useCollectionCalls(dispatch);
  useCollectionSitemapCalls(dispatch);
  useCollectionSubs(dispatch);
  useConstantCalls(dispatch);
  useBackButton(dispatch);
}

function useUserLogin(dispatch) {
  const isLoggedIn = useSelector(isLoggedInRS);

  useEffect(() => {
    if (isLoggedIn) return subsUserProfile(dispatch);
  }, [isLoggedIn]);
}

function useBuildingSelect(dispatch) {
  const selectedBuilding = useSelector(selectedBuildingRS);
  const buildings = useSelector(buildingsRS);

  useEffect(() => {
    if (selectedBuilding) {
      setMenuById(buildings[selectedBuilding]?.menu, dispatch);
      dispatch(setCurrentPath([]));
    }
  }, [selectedBuilding]);
}

export function useNavigateToPath(dispatch) {
  const path = window.location.pathname;
  const currentServices = useSelector(currentServicesRS);

  function getServiceFromList(service) {
    if (currentServices && service in currentServices) return service;
    return getDefaultService();
  }

  function getLocationFromList(service, location) {
    if (currentServices && currentServices[service] && location in currentServices[service].Location) return location;
    return "ALL";
  }

  useEffect(() => {
    let service, location, pathSegments;
    if (currentServices) {
      const paths = path.split("/");

      if (paths[1] === "") {
        service = getDefaultService();
        location = "ALL";
      } else if (paths.length === 2) {
        service = getServiceFromList(paths[1]);
        location = "ALL";
      } else if (paths.length > 2) {
        service = getServiceFromList(paths[1]);
        location = getLocationFromList(paths[1], paths[2]);
        pathSegments = paths.slice(3);
      }
      dispatch(setLocation({ service, location }));
      if (pathSegments && pathSegments.length) dispatch(setPathSegmemts(pathSegments));
    }
  }, [path, currentServices]);
}

function useSitemap(dispatch) {
  const selectedBuilding = useSelector(selectedBuildingRS);
  const buildings = useSelector(buildingsRS);
  const service = useSelector(serviceRS);
  const location = useSelector(locationRS);
  const path = window.location.pathname;

  const sitemap = buildings?.[selectedBuilding].SubServices?.[service]?.Location?.[location];

  useEffect(() => {
    if (service) {
      getSiteMap(sitemap, dispatch);
    }
  }, [service, sitemap, path]);
}

function useIotCalls(dispatch) {
  const sampling = useSelector(samplingRS);
  const iotCalls = useSelector(iotCallsRS);

  useEffect(() => {
    if (!iotCalls) return;
    if (sampling) {
      const interval = setInterval(() => {
        executeIotCalls(dispatch, iotCalls);
      }, sampling);
      return () => clearInterval(interval);
    } else {
      executeIotCalls(dispatch, iotCalls);
    }
  }, [iotCalls, sampling]);
}

function useIotSitemapCalls(dispatch) {
  const sampling = useSelector(samplingRS);
  const iotSitemapCalls = useSelector(iotSitemapCallsRS);

  useEffect(() => {
    if (!iotSitemapCalls) return;
    if (sampling) {
      const interval = setInterval(() => {
        executeIotSitemapCalls(dispatch, iotSitemapCalls);
      }, sampling);
      return () => clearInterval(interval);
    } else {
      executeIotSitemapCalls(dispatch, iotSitemapCalls);
    }
  }, [iotSitemapCalls, sampling]);
}

function useDocCalls(dispatch) {
  const docCalls = useSelector(docCallsRS);

  useEffect(() => {
    if (!docCalls) return;
    executeDocCalls(dispatch, docCalls);
  }, [docCalls]);
}

function useCollectionCalls(dispatch) {
  const collectionCalls = useSelector(collectionCallsRS);

  useEffect(() => {
    if (!collectionCalls) return;
    executeCollectionCalls(dispatch, collectionCalls);
  }, [collectionCalls]);
}

function useCollectionSitemapCalls(dispatch) {
  const collectionSitemapCalls = useSelector(collectionSitemapCallsRS);

  useEffect(() => {
    if (!collectionSitemapCalls) return;
    executeCollectionSitemapCalls(dispatch, collectionSitemapCalls);
  }, [collectionSitemapCalls]);
}

function useCollectionSubs(dispatch) {
  const collectionSubs = useSelector(collectionSubsRS);

  useEffect(() => {
    if (!collectionSubs) return;
    return subsCollections(dispatch, collectionSubs);
  }, [collectionSubs]);
}

function useConstantCalls(dispatch) {
  const constantCalls = useSelector(constantCallsRS);

  useEffect(() => {
    if (!constantCalls) return;
    for (const iot in constantCalls) {
      const data = constantCalls[iot];
      dispatch(setIotData({ data, iot }));
    }
  }, [constantCalls]);
}

function useBackButton(dispatch) {
  useEffect(() => {
    const handleBackButton = () => {
      dispatch(
        setCurrentPath([
          {
            link: getDefaultService(),
            label: "Home",
          },
        ])
      );
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
}

export function getParams() {
  const minusNDays = (number) => new Date(new Date().setDate(new Date().getDate() - number));
  const minusNMonths = (number) => new Date(new Date().setMonth(new Date().getMonth() - number));
  const minusNQuarter = (number) => {
    const date = new Date();
    date.setDate(1);
    const newMonth = (Math.floor(date.getMonth() / 3) - number) * 3;
    return new Date(date.setMonth(newMonth));
  };

  const setToDay = (date, daytoset) => {
    const currentDay = date.getDay();
    var distance = daytoset - currentDay;
    return new Date(date.setDate(date.getDate() + distance));
  };

  const setToDate = (date, datetoset) => new Date(date.setDate(datetoset));

  const minusDays = (numberOfDays) => toISODateNoHyphen(minusNDays(numberOfDays));
  const minusWeeks = (numberOfWeeks) => toISODateNoHyphen(minusNDays(7 * numberOfWeeks));
  const minusMonths = (numberOfMonths) => toISODateNoHyphen(minusNMonths(numberOfMonths));
  const minusQuarters = (numberOfQuarter) => toISODateNoHyphen(minusNQuarter(numberOfQuarter));
  const minusYears = (numberOfYears) => toISODateNoHyphen(minusNMonths(12 * numberOfYears));
  const setDayMinusWeeks = (daytoset, numberOfWeeks) => toISODateNoHyphen(setToDay(minusNDays(7 * numberOfWeeks), daytoset));
  const setDateMinusMonths = (datetoset, numberOfMonths) => toISODateNoHyphen(setToDate(minusNMonths(numberOfMonths), datetoset));

  return {
    minusDays,
    minusWeeks,
    minusMonths,
    minusQuarters,
    minusYears,
    setDayMinusWeeks,
    setDateMinusMonths,
  };
}

function getDefaultService() {
  if (window.innerWidth < 500) return "subMobile";
  return "subMainDashboard";
}
