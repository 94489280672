import { createSlice } from "@reduxjs/toolkit";
import { formatTableText } from "../../components/utils/FormatTableText";

const initialState = {
  appState: { fullScreen: false },
};

export const ContentSlice = createSlice({
  name: "contentslice",
  initialState,
  reducers: {
    setContent(state, { payload }) {
      const { layout, type } = payload;
      let updatedLayout;
      switch (type) {
        case "DataTable":
          updatedLayout = updateLayoutDataTable(layout);
          return { ...payload, layout: { ...state.layout, ...updatedLayout } };
        case "CardDashboard":
        default:
          updatedLayout = layout;
          return { ...payload, layout: updatedLayout };
      }
    },
    setSettingsByKey(state, { payload }) {
      const { key, data } = payload;
      if (!state.settings) state.settings = {};
      state.settings[key] = data;
    },
    setSettingsByKeys(state, { payload }) {
      const { keys, data } = payload;
      if (!state.settings) state.settings = {};
      state.settings[keys[0]][keys[1]] = data;
    },
    setBillingSubServiceDetails(state, { payload }) {
      const { serviceIndex, subServiceIndex, data } = payload;
      for (const key in data) {
        state.settings.selectedTenant.services[serviceIndex].subServices[subServiceIndex][key] = data[key];
      }
    },
    setFullScreen(state, { payload }) {
      if (!state.appState) state.appState = {};
      state.appState.fullScreen = payload;
    },
  },
});

export const { setContent, setSettingsByKey, setSettingsByKeys, setFullScreen, setBillingSubServiceDetails } = ContentSlice.actions;

export const layoutRS = (state) => state.content.layout;
export const settingsRS = (state) => state.content.settings;
export const settingsByKeyRS = (key) => (state) => state.content?.settings?.[key];
export const typeRS = (state) => state.content.type;
export const fullScreenRS = (state) => state.content?.appState?.fullScreen;

export default ContentSlice.reducer;

function updateLayoutDataTable(layout) {
  return layout.map((layoutItem) => ({
    ...layoutItem,
    data: {
      ...layoutItem.data,
      columns: updateDataTableColumns(layoutItem.data.columns),
    },
  }));
}

function updateDataTableColumns(columns) {
  return columns.map((column) => ({
    ...column,
    renderHeader: formatTableText(column.headerData),
    renderCell: formatTableText(column.cellData),
  }));
}
