import { useSelector } from "react-redux";
import { iotDataRS } from "../../services/redux/BuildingSlice";
import { evaluateExpressionInContext } from "./RuleEngineInContext";

export function extractItemsBetweenIndices(arr, startIndex, endIndex) {
  if (!Array.isArray(arr) || startIndex < 0 || endIndex >= arr.length || startIndex > endIndex) {
    return [];
  }
  return arr.slice(startIndex, endIndex + 1);
}

export function numberWithCommas(x, y) {
  if (!x) return "";
  var myformat = new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 1,
    minimumFractionDigits: y,
  });
  x = Math.round(x * Math.pow(10, y)) / Math.pow(10, y);
  return myformat.format(x);
}

export function toISODate(date) {
  if (!date) return "";
  return date.toISOString().substr(0, 10);
}

export function fromISODate(date) {
  if (!date) return new Date();
  const year = Number(date.substr(0, 4));
  const month = Number(date.substr(4, 2)) - 1;
  const day = Number(date.substr(6, 2));

  return new Date(year, month, day);
}

export function toISODateNoHyphen(date) {
  return date.toISOString().substr(0, 10).replaceAll("-", "");
}

export function getIconClassByIotType(value, type) {
  switch (type) {
    case "motion":
      if (value === "Occupied") return "motion activated";
      if (value === "Unoccupied") return "deactivated";
      return "alarm";
    case "door":
      if (value === "Open") return "door activated";
      if (value === "Closed") return "deactivated";
      return "alarm";
    case "bulb":
      if (value === "ON" || Number(value) > 0) return "bulb activated";
      if (value === "OFF" || Number(value) === 0) return "deactivated";
      return "alarm";
    case "flow-path":
      if (Number(value) > 0) return "squiggle forward";
      if (Number(value) < 0) return "squiggle backward";
      if (Number(value) === 0) return "squiggle no-flow";
      return "squiggle alarm";
    default:
      return "alarm";
  }
}

export function convertBinary2OnOff(value) {
  if (value === "0") return "OFF";
  if (value === "1") return "ON";
  return "NA";
}

export function dynamicLiteral(stringFromServer, data) {
  return stringFromServer.replace(/\${(.*?)}/g, (_, match) => data[match] ?? "");
}

export function useInsertIotDataInLiteral(elem) {
  const iotData = useSelector(iotDataRS);

  if (!elem) return "";
  const { literal } = elem;
  if (!literal) return "";
  const result = evaluateExpressionInContext(literal, iotData);
  return result;
}

export function useInsertIotDataInLiteralGauge(elem) {
  const result = {
    percent: 0,
    label: "",
  };

  result.value = useInsertIotDataInLiteral(elem ? elem.value : null);
  result.label = useInsertIotDataInLiteral(elem ? elem.label : null);
  result.thresholds = elem ? elem.thresholds.map((e) => e) : [0.7, 0.9, 1];

  return result;
}

export function useOhPersistenceWithChart(oHData) {
  const iotData = useSelector(iotDataRS);

  if (!oHData) return "";
  const { series } = oHData;

  if (!series && !series.length) return "";
  const result = [];
  const options = {
    legend: {
      show: true,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: oHData.yaxis.map((e) => ({
      ...e,
      labels: {
        formatter: (value) => numberWithCommas(value * e.mf, e.decimal),
      },
    })),
    ...oHData.options,
  };

  series.forEach(({ value, title }) => {
    if (!iotData[value]) return "";

    let data, name;
    if (typeof iotData[value] === "string") {
      ({ data, name } = JSON.parse(iotData[value]));
    } else {
      ({ data, name } = iotData[value]);
    }

    if (!name) return "";
    result.push({
      name: title,
      data: data.map((e) => [e.time, e.state]),
    });
  });
  return { series: result, options };
}

export function useDataTableFromServer(elem) {
  const iotData = useSelector(iotDataRS);

  if (!elem) return [];
  const { value } = elem;
  if (!value) return [];
  if (!iotData[value]) return [];
  let result = iotData[value];

  return result;
}
