import { useDisplaysize } from "../../../services/mui/utils";
import FixedBottomNavigation from "./FixedBottomNavigation";
import Footer from "./Footer";

export default function FooterSelector() {
  const displaySize = useDisplaysize();

  if (displaySize === "sm") return <FixedBottomNavigation />;
  return <Footer />;
}
