import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { sideBarOpenRS } from "../redux/AppUISlice";

export function useDisplaysize() {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumDisplay = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"));
  if (smallDisplay) return "sm";
  if (mediumDisplay) return "md";
  if (largeDisplay) return "lg";
}

export function useScreenMode() {
  const open = useSelector(sideBarOpenRS);
  const displaySize = useDisplaysize();

  if (open && displaySize !== "sm") return "largeWithSideMenu";
  if (!open && displaySize !== "sm") return "largeWithoutSideMenu";
  return "mobile";
}
