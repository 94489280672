import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  service: "",
  location: "",
  pathSegments: [],
  sampling: 0,
  sitemap: {
    Iots: {},
  },
  iotData: {},
};

export const BuildingSlice = createSlice({
  name: "buildingslice",
  initialState,
  reducers: {
    resetBuilding: () => initialState,
    setSampling(state, actions) {
      state.sampling = actions.payload;
    },
    setService(state, actions) {
      return { ...initialState, iotData: {}, service: actions.payload };
    },
    setLocation(state, { payload }) {
      const { service, location } = payload;
      return { ...initialState, iotData: {}, service, location };
    },
    setPathSegmemts(state, { payload }) {
      state.pathSegments = [...payload];
    },
    setSiteMap(state, actions) {
      state.sitemap = { ...actions.payload };
    },
    setIotData(state, actions) {
      const { data, iot } = actions.payload;
      state.iotData[iot] = data;
    },
    setIotCommands(state, { payload }) {
      state.sitemap.Iots.commands = { ...state.sitemap.Iots.commands, ...payload };
    },
    deleteIotDataByKey(state, { payload }) {
      delete state.iotData[payload];
    },
  },
});

export const { resetBuilding, setService, setSiteMap, setIotData, setSampling, setIotCommands, setLocation, deleteIotDataByKey, setPathSegmemts } =
  BuildingSlice.actions;

export const serviceRS = (state) => state.building.service;
export const locationRS = (state) => state.building.location;
export const pathSegmentsRS = (state) => state.building.pathSegments;
export const samplingRS = (state) => state.building.sampling;
export const sitemapRS = (state) => state.building.sitemap;
export const iotCallsRS = (state) => state.building.sitemap.Iots.calls;
export const iotCommandsRS = (state) => state.building.sitemap.Iots.commands;
export const iotSitemapCallsRS = (state) => state.building.sitemap.Iots.ohSitemaps;
export const docCallsRS = (state) => state.building.sitemap.Iots.documents;
export const collectionCallsRS = (state) => state.building.sitemap.Iots.collections;
export const collectionSubsRS = (state) => state.building.sitemap.Iots.subsCollections;
export const collectionSitemapCallsRS = (state) => state.building.sitemap.Iots.collectionSitemap;
export const constantCallsRS = (state) => state.building.sitemap.Iots.constants;
export const iotDataRS = (state) => state.building.iotData;
export const iotDataByKeyRS = (key) => (state) => state.building.iotData[key];

export default BuildingSlice.reducer;
