import { Icon, SvgIcon } from "@mui/material";
import { ReactComponent as hc2_cctv } from "../../assets/icons/hc2_cctv.svg";
import { ReactComponent as hc2_gate } from "../../assets/icons/hc2_gate.svg";
import { ReactComponent as hc2_home } from "../../assets/icons/hc2_home.svg";
import { ReactComponent as hc2_intercom } from "../../assets/icons/hc2_intercom.svg";
import { ReactComponent as hc2_panic } from "../../assets/icons/hc2_panic.svg";
import { BatteryFull, Bolt, DirectionsWalk, WaterDrop } from "@mui/icons-material";

export default function LoadedIcon(props) {
  const { iconName, ...rest } = props;

  switch (iconName) {
    case "app_flavour":
      return <img src={`/flavours/${process.env.REACT_APP_EC3_FLAVOUR}/logo.svg`} alt="app" {...rest} />;
    case "hc2_cctv":
      return <SvgIcon component={hc2_cctv} {...rest} />;
    case "hc2_gate":
      return <SvgIcon component={hc2_gate} {...rest} />;
    case "hc2_home":
      return <SvgIcon component={hc2_home} {...rest} />;
    case "hc2_intercom":
      return <SvgIcon component={hc2_intercom} {...rest} />;
    case "hc2_panic":
      return <SvgIcon component={hc2_panic} {...rest} />;
    case "battery_full_alt":
      return <BatteryFull {...rest} />;
    case "bolt":
      return <Bolt {...rest} />;
    case "directions_walk":
      return <DirectionsWalk {...rest} />;
    case "water_drop":
      return <WaterDrop {...rest} />;

    default:
      return <Icon {...rest}>{iconName}</Icon>;
  }
}
