export const drawerWidth = 240;
export const miniDrawerWidth = 64;
export const topBarHeight = 64;
export const contentLRPadding = 32; // Left + Right
export const contentTBPadding = 70; // Top + Bottom
export const minCardHeight = 150;
export const maxCardHeight = 260;
export const hc2cardHeight = 120;
export const footerHeight = 20;
export const footerMargin = 8;
export const contentScreenPadding = 16;
export const hc2RingSize = 200;
export const FixedBottomNavigationHeight = 56;
export const DataTableControlBarHeight = 72;
