import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import ConfirmationModal from "../ConfirmationModal";
import { mobileMenuRS } from "../../../services/redux/AppUISlice";
import { useNavigateToPath } from "../../utils/SetupApp";
import LoadedIcon from "../../utils/LoadedIcon";

export default function FixedBottomNavigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileMenu = useSelector(mobileMenuRS);

  const [modalOpen, setModalOpen] = useState(false);
  const [iotData, setIotData] = useState({});

  useNavigateToPath(dispatch);

  async function actionSelector(type, action) {
    switch (type) {
      case "link":
        const { service, location } = action;
        navigate(`/${service}/${location}`);
        break;
      case "command":
        setModalOpen(!modalOpen);
        setIotData(action);
        break;

      default:
        break;
    }
  }

  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation showLabels sx={{ backgroundColor: "primary.main" }}>
        {mobileMenu &&
          mobileMenu.map(({ label, icon, type, action }, key) => (
            <BottomNavigationAction
              key={key}
              onClick={() => actionSelector(type, action)}
              label={label}
              icon={<LoadedIcon iconName={icon} />}
              style={{ color: "white" }}
            />
          ))}
      </BottomNavigation>
      <ConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} onClose={() => setModalOpen(false)} action={iotData} />
    </Paper>
  );
}
