import { createSlice } from "@reduxjs/toolkit";

export const UserStateSlice = createSlice({
  name: "userstate",
  initialState: {
    isLoggedIn: false,
    profile: {},
  },
  reducers: {
    setUserLoggedIn(state, actions) {
      state.isLoggedIn = actions.payload;
    },
    setUserProfile(state, actions) {
      state.profile = { ...actions.payload };
    },
  },
});

export const { setUserLoggedIn, setUserProfile } = UserStateSlice.actions;

export const isLoggedInRS = (state) => state.userState.isLoggedIn;
export const buildingsRS = (state) => state.userState.profile.Buildings;
export const selectedBuildingRS = (state) => state.userState.profile?.lastViewed?.Building;
export const currentServicesRS = (state) => state.userState.profile.Buildings?.[state.userState.profile?.lastViewed?.Building]?.SubServices;
export const fNameRS = (state) => state.userState.profile.userData.fname;

export default UserStateSlice.reducer;

// {
//   type: 'userstate/setUserLoggedIn',
//   payload: true
// }
