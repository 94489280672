import { Chip, Icon } from "@mui/material";

export function formatTableText(elem) {
  if (!elem) return null;
  const { formatType, data } = elem;

  switch (formatType) {
    case "Bold":
      return () => <strong> {data} </strong>;
    case "assetStatus":
      return ({ value }) => getAssetStatusChip(value);
    case "icon":
      return () => getIcon(data);
    case "date":
      return ({ value }) => getDate(value);
    default:
      return () => <> {data ?? ""} </>;
  }
}

function getAssetStatusChip(value) {
  switch (value) {
    case "Active":
      return <Chip label="Active" variant="outlined" sx={{ background: "blue", color: "white" }} />;
    case "Damaged":
      return <Chip label="Damaged" variant="outlined" sx={{ background: "red", color: "white" }} />;
    case "Inactive":
      return <Chip label="Inactive" variant="outlined" sx={{ background: "grey", color: "white" }} />;
    default:
      return value;
  }
}

function getIcon(data) {
  const { icon, color } = data;
  return <Icon sx={{ color }}>{icon}</Icon>;
}

function getDate(value) {
  if (!value) return "";
  const result = value.toDate().toISOString();
  return result.substring(0, 10);
}
